import styled from 'styled-components'
import { motion } from 'framer-motion'

const variants = {
  visible: { zIndex: 100, opacity: 1, transition: { duration: 0.3 } },
  hidden: { opacity: 0 },
}

export const BoxStyled = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants,
}))`
  font-family: 'Open Sans';
  position: absolute;
  right: 20%;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  top: 100px;
  right: 20%;
  width: 300px;
  border-radius: 10px;
`

export const MediumBoxStyled = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants,
}))`
  font-family: 'Open Sans';
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  top: 100px;
  right: 10%;
  width: 250px;
  border-radius: 10px;
`

export const SmallBoxStyled = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants,
}))`
  font-family: 'Open Sans';
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  top: 45px;
  right: 60px;
  width: 199px;
  border-radius: 10px;
`
