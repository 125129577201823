import React from 'react'
import { ConfigProvider } from 'antd'
import { ApolloProvider, useQuery } from '@apollo/client'
import esES from 'antd/es/locale/es_ES'
import isBetween from 'dayjs/plugin/isBetween'
import { USER } from '@src/helpers/localQueries/userQuery'
import Authentication from '@src/pages/authentication'
import { client } from './apollo'
import { Helmet } from 'react-helmet'
import { UserLocalQuery } from './types'

import advancedFormat from 'dayjs/plugin/advancedFormat'
import isToday from 'dayjs/plugin/isToday'

import dayjs from 'dayjs'

import 'dayjs/locale/es'
dayjs.locale('es') // use Spanish locale globally
dayjs.extend(advancedFormat)
dayjs.extend(isToday)
dayjs.extend(isBetween)

const IsLoggedIn = ({ element }) => {
  const { data } = useQuery<UserLocalQuery>(USER)
  return data?.user?.isLoggedIn ? element : <Authentication />
}

export default ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <ConfigProvider locale={esES}>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>The Cat Club</title>
            <link rel="canonical" href="https://professionals.thecatclub.cl/" />
          </Helmet>
        </div>
        <IsLoggedIn element={element} />
      </ConfigProvider>
    </ApolloProvider>
  )
}
