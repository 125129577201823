import React, { useState, useEffect, useRef } from 'react'
import Logo from '../logo'
import styles from './styles.module.css'
import { useQuery } from '@apollo/client'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { clearUserData } from '@src/state/initialState'
import { USER } from '@src/helpers/localQueries/userQuery'

import { noImageUrl } from './noImage'
import { logout } from '@src/utils/auth'
import sleep from '@src/helpers/sleep'
import { BoxStyled, MediumBoxStyled, SmallBoxStyled } from './styles'
import { navigate } from 'gatsby'
import { isEmpty } from 'ramda'

interface UserData {
  id: string
  professionalId: string
  name: string
  picture: string
  title: string
  tokenId: string
  email: string
  accessToken: string
  isLoggedIn: boolean
  expiresAt: string
}

interface UserLocalQuery {
  user: UserData
}

const startWidth = typeof window !== 'undefined' && window.innerWidth
const startHeight = typeof window !== 'undefined' && window.innerHeight

const Header: React.FC = () => {
  const targetRef = useRef()
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [dimensions, setDimensions] = useState({
    width: startWidth,
    height: startHeight,
  })
  const { data } = useQuery<UserLocalQuery>(USER)

  const updateDimensions = () => {
    if (typeof window !== 'undefined') {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateDimensions)
      return () => window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const localLogOut = async () => {
    await sleep(500)
    clearUserData()
  }

  const onLogout = () => {
    localLogOut()
    logout()
  }

  const renderExample = () => {
    const Component =
      dimensions.width < 600
        ? SmallBoxStyled
        : dimensions.width < 1500
        ? MediumBoxStyled
        : BoxStyled
    return (
      <Component animate={isVisible ? 'visible' : 'hidden'}>
        <div className={styles.options} onClick={() => navigate('/home')}>
          Inicio
        </div>
        <div className={styles.options} onClick={() => navigate('/profile')}>
          Perfil
        </div>
        <div className={styles.options} onClick={() => navigate('/support')}>
          Soporte
        </div>
        <div className={styles.options} onClick={onLogout}>
          Cerrar sesión
        </div>
      </Component>
    )
  }

  const renderUserOptions = () => {
    return (
      <div
        className={styles.professional}
        onClick={() => setIsVisible(prevState => !prevState)}>
        <img
          src={
            !data?.user?.picture || !data?.user?.picture[0]
              ? noImageUrl
              : data?.user?.picture
          }
          alt={Math.random().toString()}
        />
        <div className={styles.data}>
          <div className={styles.name}>{data?.user?.name}</div>
          <div className={styles.title}>{data?.user?.title}</div>
        </div>
        <div className={styles.icon}>
          <MdKeyboardArrowDown size={20} color="#DADADA" />
        </div>
        {renderExample()}
      </div>
    )
  }

  return (
    <header
      style={{
        background: `white`,
        marginBottom: `1.45rem`,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.12)',
      }}>
      <div className={styles.container} ref={targetRef}>
        <div className={styles.logoContent}>
          {dimensions.width < 1024 ? (
            <span />
          ) : (
            <>
              <Logo />
              <div className={styles.divider} />
              <div className={styles.description}>Profesionales</div>
            </>
          )}
        </div>
        {renderUserOptions()}
      </div>
    </header>
  )
}

export default Header
