import React, { useEffect } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { VERIFICATION } from './login.gql'
import BackgroundImage from './backgroundImage'
import { updateUserData, clearUserData } from '@src/state/initialState'
import { VERIFY_PROFESSIONAL } from '@src/helpers/mutations/professionals.gql'
import { handleAuthentication } from '@src/utils/auth'
import { userVar } from '@src/state/initialState'
import { logout } from '@src/utils/auth'
import { navigate } from 'gatsby'
import { message } from 'antd'

import { Spin } from 'antd'
import Logo from '../logo'

import styles from './styles.module.css'

interface VerifyVars {
  tokenId: string
  accessToken: string
  email: string
  auth0UserId: string
}

const VerifyAuthentication: React.FC = () => {
  const [verifyUser] = useMutation<VerifyVars>(VERIFICATION)
  const [verifyProfessional] = useMutation(VERIFY_PROFESSIONAL)

  const onAuthentication = async (params: {
    tokenId?: string
    accessToken: string
    email: string
    auth0UserId: string
  }) => {
    console.log({ params })

    try {
      const response = await verifyUser({ variables: { input: params } })
      if (!response || !response.data) return
      const professionalData = await verifyProfessional({
        variables: { userId: params.auth0UserId },
      })
      console.log({ professionalData })

      if (!professionalData || !professionalData.data) return
      return professionalData.data.verifyProfessionalByUserId
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleAuthentication()
  }, [])

  useEffect(() => {
    const verifyUserOnServer = async () => {
      const data = userVar()
      if (data.accessToken) {
        const params = {
          tokenId: data.tokenId,
          accessToken: data.accessToken,
          email: data.email,
          auth0UserId: data.id,
        }

        const professional = await onAuthentication(params)

        if (professional && professional.id) {
          updateUserData({
            ...data,
            professionalId: professional.id,
            name: professional.name,
            title: professional.title,
            isLoggedIn: true,
            picture: professional?.picture[0]?.url || [],
          })
          navigate('/home')
        } else {
          console.log('hereeeeee')

          clearUserData()
          message.error('Sorry, unauthorized')
          logout()
        }
      }
    }
    verifyUserOnServer()
  }, [userVar()])

  return (
    <BackgroundImage image="login-back.png" className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Logo />
          <div className={styles.divider} />
          <div className={styles.description}>Profesionales</div>
        </div>
        <div className={styles.buttonContent}>
          <Spin size="large" />
        </div>
      </div>
    </BackgroundImage>
  )
}

export default VerifyAuthentication
