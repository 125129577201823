import { gql } from '@apollo/client'

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`

const USER = gql`
  query User {
    user @client {
      id
      professionalId
      name
      title
      picture
      tokenId
      email
      accessToken
      isLoggedIn
      expiresAt
    }
  }
`

export { IS_LOGGED_IN, USER }
