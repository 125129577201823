/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import Header from '../header'
import styles from './styles.module.css'
import './styles.css'

const Layout = ({ children }) => {
  const url = typeof window !== 'undefined' && window.location.href
  const isLogin = url && url.includes('authentication')

  if (isLogin) return <main>{children}</main>

  return (
    <>
      <Header />
      <div className={styles.main}>
        <main>{children}</main>
        <footer className={styles.footer}>{` `}</footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
