import { ApolloClient, InMemoryCache, makeVar } from '@apollo/client'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
// import { persistCache } from 'apollo-cache-persist'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'isomorphic-fetch'
import { logout } from '../utils/auth'

import { userVar } from './initialState'

const url = typeof window !== 'undefined' && window?.location?.href

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, location, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`,
      ),
    )

    if (
      graphQLErrors[0].extensions?.exception?.response?.statusCode === 401 ||
      graphQLErrors[0].message === 'Unauthorized'
    )
      logout()
  }

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const httpLink = createUploadLink({
  uri:
    url && url.includes('localhost')
      ? 'http://localhost:7000/graphql'
      : process.env.GATSBY_ENVIRONMENT === 'dev'
      ? process.env.GATSBY_DEV_API_URL
      : process.env.GATSBY_ENVIRONMENT === 'prod'
      ? process.env.GATSBY_PROD_API_URL
      : '',
})

const authLink = setContext((_, { headers }) => {
  const token = userVar().accessToken
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        user: {
          read() {
            return userVar()
          },
        },
      },
    },
  },
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

const link = ApolloLink.from([errorLink, authLink, httpLink])
export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  fetch,
  link,
  cache,
  defaultOptions,
})
